import {
  Container, Col, Row, Image,
} from 'react-bootstrap';
import { FaRegHeart } from 'react-icons/fa6';

import SectionTitle from '@shared/components/SectionTitle';

// --------------------------------------------------

const About = () => {
  const exp = new Date().getFullYear() - 2018;

  return (
    <section id="about">
      <Container>
        <SectionTitle group={{ className: 'mb-5' }}>About <b>Me</b></SectionTitle>
        <Row>
          <Col lg={7} md={7} className="offset-lg-1">
            <div className="about-description">
              <h4>Passionate about JavaScript <FaRegHeart /></h4>
              I am a software engineer with {exp}+ years of experience and I develop efficient applications using React,
              one of the main frameworks used by the JavaScript market, as well as other front-end tools such as
              HTML5, CSS3, and Sass. On the back-end, I use the NestJS and Express.js frameworks for Node.js.
              Other tools I use are TypeScript and Docker.<br />
              I have extensive experience in agile environments, contributing as an essential member of
              multidisciplinary teams that have designed and implemented a variety of applications.<br />
              My career as a senior employee has provided me with solid problem-solving, team collaboration and
              leadership skills, all of which have been refined throughout my previous professional experiences.
            </div>
          </Col>
          <Col lg={4} md={5}>
            <div className="about-image">
              <Image src={`${process.env.PUBLIC_URL}/assets/images/profile_photo_2.jpg`} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

// --------------------------------------------------

export default About;
