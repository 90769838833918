import Navbar from '@layout/Navbar';
import Home from '@pages/Home';
import Footer from '@layout/Footer';

// --------------------------------------------------

const App = () => (
  <>
    <Navbar />
    <Home />
    <Footer />
  </>
);

export default App;
