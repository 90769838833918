import { Container, Navbar as BsNavbar, Nav } from 'react-bootstrap';

// --------------------------------------------------

const Navbar = () => (
  <BsNavbar id="navbar" expand="lg" fixed="top" bg="white">
    <Container>
      <BsNavbar.Brand
        href="#"
        onClick={(e) => {
          e.preventDefault();
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        DIOGO MEDINA
      </BsNavbar.Brand>
      <BsNavbar.Toggle className="ms-auto" aria-controls="navbar-nav" />
      <BsNavbar.Collapse id="navbar-nav">
        <Nav className="ms-auto">
          <NavLink href="#">Home</NavLink>
          <NavLink href="#about">About</NavLink>
          <NavLink href="#skills">Skills</NavLink>
        </Nav>
      </BsNavbar.Collapse>
    </Container>
  </BsNavbar>
);

// --------------------------------------------------

interface NavLinkProps {
  readonly children: React.ReactNode;
  readonly href: string;

}

const NavLink = ({ children, href }: NavLinkProps) => (
  <Nav.Link
    href={href}
    onClick={(e) => {
      e.preventDefault();

      const elmId = href.replace('#', '');
      let top = 0;

      if (elmId !== '') {      
        const elm = document.getElementById(elmId);
        if (elm) top = elm.offsetTop - 56;
      }
      
      window.scroll({ top, behavior: 'smooth' });
    }}
  >
    {children}
  </Nav.Link>
);

export default Navbar;
