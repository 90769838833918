import { Container, Row, Col, Card } from 'react-bootstrap';

import { IconBaseProps, IconType } from 'react-icons';
import {
  FaHtml5, FaCss3Alt, FaSass, FaJs, FaReact, FaNode, FaPython, FaGitAlt, FaDocker,
} from 'react-icons/fa6';
import { BiLogoTypescript } from 'react-icons/bi';
import { SiExpress, SiNestjs, SiSelenium, SiMicrosoftsqlserver, SiMongodb } from 'react-icons/si';
import { GrMysql } from 'react-icons/gr';

import styled from 'styled-components';

import SectionTitle from '@shared/components/SectionTitle';

// --------------------------------------------------

const Skills = () => (
  <section id="skills">
    <Container>
      <SectionTitle>My <b>Skills</b></SectionTitle>
      <Row className="justify-content-center">
        {skillsList.map((v, i) => (
          <Col key={i} xxl={2} lg={3} md={4} sm={6} className="my-3">
            <Skill {...v} />
          </Col>
        ))}
      </Row>
    </Container>
  </section>
);

// --------------------------------------------------

const FaPythonCustom = (props: IconBaseProps) => (
  <>
    <svg width="0" height="0">
      <linearGradient id="faPythonGradient" x1="0" x2="100%" y1="0" y2="100%">
        <stop stopColor="#306998" offset="40%" />
        <stop stopColor="#ffd43b" offset="60%" />
      </linearGradient>
    </svg>
    <FaPython {...props} size="60px" style={{ stroke: "url(#faPythonGradient)", fill: "url(#faPythonGradient)" }} />
  </>
)

const SiMicrosoftsqlserverCustom = (props: IconBaseProps) => (
  <>
    <svg width="0" height="0">
      <linearGradient id="siMicrosoftsqlserverGradient" x1="0%" x2="100%" y1="0" y2="100%">
        <stop stopColor="#e2302a" offset="40%" />
        <stop stopColor="#909ca9" offset="60%" />
      </linearGradient>
    </svg>
    <SiMicrosoftsqlserver {...props} size="60px" style={{ stroke: "url(#siMicrosoftsqlserverGradient)", fill: "url(#siMicrosoftsqlserverGradient)" }} />
  </>
);

const skillsList: SkillProps[] = [
  { name: 'HTML5', startYear: 2018, color: '#e34c26', Icon: FaHtml5 },
  { name: "CSS3", startYear: 2018, color: "#264de4", Icon: FaCss3Alt },
  { name: "Sass", startYear: 2018, color: "#cc6699", Icon: FaSass },
  { name: "JavaScript", startYear: 2018, color: "#f0db4f", Icon: FaJs },
  { name: "React", startYear: 2019, color: "#61dbfb", Icon: FaReact },
  { name: "React Navite", startYear: 2019, color: "#61dbfb", Icon: FaReact },
  { name: "Node.js", startYear: 2019, color: "#3c873a", Icon: FaNode },
  { name: "Express.js", startYear: 2019, color: "#000000", Icon: SiExpress },
  { name: "NestJs", startYear: 2020, color: "#e0234e", Icon: SiNestjs },
  { name: "TypeScript", startYear: 2020, color: "#007acc", Icon: BiLogoTypescript },
  { name: "Python", startYear: 2020, color: "#ffd43b", Icon: FaPythonCustom },
  { name: "Selenium", startYear: 2020, color: "#00b400", Icon: SiSelenium },
  { name: "Git", startYear: 2019, color: "#f34f29", Icon: FaGitAlt },
  { name: "Docker", startYear: 2020, color: "#0db7ed", Icon: FaDocker },
  { name: "MySQL", startYear: 2018, color: "#00758f", Icon: GrMysql },
  { name: "SQL Server", startYear: 2020, color: "#e2302a", Icon: SiMicrosoftsqlserverCustom },
  { name: "MongoDB", startYear: 2020, color: "#4fa940", Icon: SiMongodb },
];

// --------------------------------------------------

interface SkillProps {
  readonly name: string;
  readonly startYear: number;
  readonly color?: string;
  readonly Icon: IconType; 
}

const Skill = ({ name, startYear, color, Icon }: SkillProps) => {
  const exp = new Date().getFullYear() - startYear;

  return (
    <SkillCard>
      <Card.Body>
        <Icon color={color} size="60px" />
        <b>{name}</b>
        <i>Experience: {exp} year{exp > 1 ? 's' : ''}</i>
      </Card.Body>
    </SkillCard>
  )
};

const SkillCard = styled(Card)`
  border: 0;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);

  & > .card-body {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;

    & > b {
      margin: .5rem 0;
    }
  }
`;

// --------------------------------------------------

export default Skills;
