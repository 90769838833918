import { Container } from 'react-bootstrap';

// --------------------------------------------------

const Footer = () => (
  <footer id="footer">
    <Container>&copy; {new Date().getFullYear()} Diogo Medina. All Rights Reserved.</Container>
  </footer>
);

export default Footer;
