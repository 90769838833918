import styled from 'styled-components';

// --------------------------------------------------

interface SectionTitleProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  readonly children: React.ReactNode;
  readonly group?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}

const SectionTitle = ({ children, group, ...props }: SectionTitleProps) => (
  <SectionTitleComp {...group}>
    <h3 {...props}>{children}</h3>
  </SectionTitleComp>
);

// --------------------------------------------------

const SectionTitleComp = styled.div`
  margin-bottom: 1rem;
  text-align: center;
  display: block;

  & > h3 {
    margin: 0;
    font-size: 2rem;
    display: inline-block;

    @media screen and (min-width: 1200px) {
      font-size: 2.5rem;
    }

    &::after {
      width: 50%;
      margin: 0.5rem auto 0 auto;
      border-top: 0.2rem solid #000;
      content: '';
      display: block;
    }
  }
`;

export default SectionTitle;
