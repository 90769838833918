import {
  Button, Container, Col, Row, Image,
} from 'react-bootstrap';

import { FaLinkedin, FaGithubSquare } from 'react-icons/fa';

// --------------------------------------------------

const Banner = () => (
  <section id="banner">
    <Container>
      <Row>
        <Col sm={8}>
          <div className="banner-title">
            <h1>
              Hi,<br/>
              I'm <span>Diogo Medina</span>
            </h1>
            <h2>Software Engineer</h2>
          </div>
          <div className="banner-links">
            <a className="banner-links__link" rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/DiogoMedina97/">
              <FaLinkedin size="40px" />
            </a>
            <a className="banner-links__link" rel="noreferrer" target="_blank" href="https://github.com/DiogoMedina97/">
              <FaGithubSquare size="40px" />
            </a>
          </div>
          <div className="banner-button">
            <Button
              href="#about"
              variant="primary"
              onClick={(e) => {
                e.preventDefault();

                const elm = document.getElementById('about');
                if (!elm) return;

                window.scroll({ top: elm.offsetTop - 56, behavior: 'smooth' });
              }}
            >
              About Me
            </Button>
          </div>
        </Col>
        <Col sm={4}>
          <div className="banner-image">
            <Image src={`${process.env.PUBLIC_URL}/assets/images/profile_photo.png`} />
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default Banner;
