import About from './components/About';
import Banner from './components/Banner';
import Skills from './components/Skills';

// --------------------------------------------------

const Home = () => (
  <>
    <Banner />
    <About />
    <Skills />
  </>
);

export default Home;
